
import { defineComponent } from 'vue';
import store from '@/store/index';
import { Button, ButtonGroup, IconButton, List, ListItem } from '@/ui/index';
import { PresenceController, WorkbreakController, TimeEntryController, SessionController } from '@/controller/';
import { User, Presence, Workbreak, Employee, TimeEntry } from '@/model';
import { ActiveWorkBar } from './components';
import { DateTimeUtils } from "@/utils/";

export default defineComponent({
    name: "Overview",
    components: { Button, ButtonGroup, IconButton, List, ListItem, ActiveWorkBar },
    data(){
        return {
            store,
            sessionUser: {} as User,
            sessionEmployee: {} as Employee,
            activePresence: {} as Presence,
            activeBreak: {} as Workbreak,
            activeTimeEntry: {} as TimeEntry,
            timeEntries: [] as Array<TimeEntry> ,
            runningEntries: [] as Array<TimeEntry>,
            lastTimeEntries: [] as Array<TimeEntry>,
            timeEntriesLoaded: false,
            presenceLoaded: false,
            breakLoaded: false,
            activeTimeTracked: '00:00 h',
            resetSwipe: false,
            activeDay: 0,
            today: 0,
            loading: false,
            daySum: 0
        }
    },
    created(){
        this.activeDay = DateTimeUtils.getDayNoonTimestamp(new Date())
        this.today = this.activeDay
        this.getSessionUser()
        setTimeout(() => this.updateActiveTimeTracked(), 15000)
    },
    computed:{
        displayActiveDay() : string {
            return DateTimeUtils.convertTimeStampToDate(this.activeDay, true)
        },
        readOnly() : boolean {
                //need to cast this - otherwise we get an error
                //Property 'activeDay' does not exist on type 'ComponentPublicInstance<{}, {}, {}, {}, {}, {}, {}, {}, false, ComponentOptionsBase<{}, {}, {}, {}, {}, ComponentOptionsMixin, ComponentOptionsMixin, {}, string, {}, {}, string>, {}>
            const activeDay = (this.activeDay as number)
            const today = (this.today as number)
            return today != activeDay
        },
        /* headline() : string {
            //const readonly = (this.readOnly as boolean)
            //const dDay = (this.displayActiveDay as string)
            //return  readonly ? (this.$t('work.yourWorksFor') + dDay) : this.$t('work.yourWorksToday')
            return this.$t('work.works') + '<span>' + dDay + '</span>'
        } */
    },
    methods:{
            //data
        async getSessionUser(){
            this.loading = true
            this.sessionUser = SessionController.sessionUser 
            this.sessionEmployee = SessionController.sessionEmployee
            this.getActivePresence()
            this.getActiveBreak()
            this.getTimeEntries()
        },
        async getTimeEntries() {
            this.timeEntriesLoaded = false
            //this.timeEntries = await TimeEntryController.fetchTimeEntries(this.sessionEmployee.uid)
            const resDay = await TimeEntryController.fetchDayTimeEntries(this.sessionEmployee.uid, this.activeDay)
            if(!resDay.error){
                this.timeEntries = resDay.items;
            } else {
                this.$notify(this.$t(resDay.error.message), { position: "right", type: "error" });
            }
            //[WS TODO] nur quick'n'dirty, damit ich laufenden von z.B. gestern habe -> sollte es ja dann nicht mehr geben
            const resRunning = await TimeEntryController.fetchRunningTimeEntries(this.sessionEmployee.uid)
            if(!resRunning.error){
                this.runningEntries = resRunning.items;
            } else {
                this.$notify(this.$t(resRunning.error.message), { position: "right", type: "error" });
            }
            //console.log("Overview:handleGetTimeEntries:timeEntries", this.timeEntries.length);
            this.lastTimeEntries = this.getLastTimeEntries()
            this.timeEntriesLoaded = true
            this.setDaySum()
            //this.getActiveWork()
            this.loading = false
        },
        /* async handleCreateTimeEntry(){
            this.activeTimeEntry = await TimeEntryController.createTimeEntry(this.sessionEmployee.uid, 0, 0, 0, 0) as TimeEntry
        }, */
        async createClonedTimeEntry(entry: TimeEntry) {
            //createTimeEntry(employeeId:number, workId:number, projectId:number, customerId:number, workstationId:number) {            
                    //TODO replace startworkstation with the current one
            const entries = await TimeEntryController.createTimeEntry(entry.employee.uid, entry.work.uid, entry.project.uid, entry.customer.uid, entry.startWorkstation)
            if (entries?.error) {
                this.$notify(this.$t(entries.error.data.error.message), { position: "right", type: "error" })
            } else if (entries?.items) {
                this.updateTimeEntries(entries.items)
            }            
        },
        async toggleTimeEntry(entry: TimeEntry, startstop: string){
            //console.log('toggleTimeEntry', entry, startstop);
            const entries = await TimeEntryController.toggleTimeEntry(entry.uid, entry.employee.uid, entry.tstamp, startstop, entry.work.uid, entry.project?.uid, entry.customer?.uid, entry.startWorkstation)
            if (entries?.error) {
                if (entries.error.data.error.code == 409) {
                    this.$notify(this.$t(entries.error.data.error.message), { position: "right", type: "warning" })
                    await this.createClonedTimeEntry(entry)
                } else {
                    this.$notify(this.$t(entries.error.data.error.message), { position: "right", type: "error" })
                }
            } else if (entries?.items) {
                this.updateTimeEntries(entries.items) 
            }
        },
        async getActivePresence(){
            this.activePresence = await PresenceController.getActivePresence(this.sessionEmployee.uid)
            this.presenceLoaded = true
        },
        async createPresence(){
            this.activePresence = await PresenceController.createPresence(this.sessionEmployee)
        },
        async updatePresence(){
            this.activePresence = await PresenceController.updatePresence(this.sessionEmployee)
            if (this.activeTimeEntry?.start) {
                this.toggleTimeEntry(this.activeTimeEntry, 'stop')
            }            
        },
        async getActiveBreak(){
            this.activeBreak = await WorkbreakController.getActiveWorkbreak(this.sessionEmployee.uid)
            this.breakLoaded = true
        },        
        async createBreak(){
            if (this.activeTimeEntry?.start) {
                this.toggleTimeEntry(this.activeTimeEntry, 'stop')
            }
            this.activeBreak = await WorkbreakController.createWorkbreak(this.sessionEmployee)
        },
        async updateBreak(){
            this.activeBreak = await WorkbreakController.updateWorkbreak(this.sessionEmployee)
        },
        async deleteTimeEntry(entry: TimeEntry){
            const deleted = await TimeEntryController.deleteTimeEntry(entry.uid, entry.tstamp)
            //console.log('deleteTimeEntry deleted', deleted)
            if (deleted?.items) this.removeTimeEntries(deleted.items)
        },
        async editTimeEntry(entry: TimeEntry){
            //this.activeTimeEntry = await TimeEntryController.deleteTimeEntry(entry.uid, entry.tstamp)
            this.$router.push('/edittimeentry/' + entry.getUid())
        },
            //functions
        getLastTimeEntries(){
            if(this.timeEntries?.length){ 
                const sorted = this.timeEntries.sort((a : TimeEntry, b : TimeEntry) => b.start - a.start);
                const active = this.getActiveWork(sorted)
                if (active) {
                    this.activeTimeEntry = active
                    this.activeTimeTracked = DateTimeUtils.convertSecondsToTime(this.activeTimeEntry?.getTimeTracked())
                } else {
                    this.activeTimeEntry = {} as TimeEntry
                    this.activeTimeTracked = ''
                }
                return sorted
            } /* else if (this.runningEntries?.length) { 
                    //[WS 2024-04-09] no longer valid anymore?
                    const active = this.getActiveWork(this.runningEntries)
                    this.activeTimeEntry = this.runningEntries[0];
                    this.activeTimeTracked = DateTimeUtils.convertSecondsToTime(this.activeTimeEntry.getTimeTracked())
                    return this.runningEntries;
            }  */
            return []
        },
        getActiveWork(entries:Array<TimeEntry>) {
                //[WS TODO:] put this in controller or object and convert the startstops to an array
            const active = entries.find((entry : TimeEntry) => {
                let running = false;
                for (let key in entry.startstops) {
                    if (!running && entry.startstops[key].start != 0 && entry.startstops[key].stop == 0) running = true;
                }
                return running;
            });
            return active;
        },
        getProjectTitle(timeEntry: TimeEntry) {
            const p = timeEntry.project
            let title = p.numberExternal ? p.numberExternal : ''
            title += p.numberInternal ? (title ? ' / ' : '') + p.numberInternal : ''
            title += p.numberSerial ? (title ? ' / ' : '') + p.numberSerial : ''
            title += p.title ? (title ? ' ' : '') + p.title : ''
            return title
        },
        convertTimeStamp(ts:any){            
            return DateTimeUtils.convertTimeStampToTime(ts)
        },
        convertSeconds(ts: any) {
            return DateTimeUtils.convertSecondsToTime(ts)
        },
        updateTimeEntries(entries: any) {
                //TODO put this into controller and get the entries again from controller
            entries.forEach((entry: any) => {
                const replaceIdx = this.timeEntries.findIndex((e: any) => e.uid == entry.uid)
                if (replaceIdx != -1) {
                    //console.log('replaceIdx', replaceIdx)
                    //console.log('entry', entry)
                    this.timeEntries.splice(replaceIdx, 1, entry)
                } else {
                    this.timeEntries.push(entry)
                }
            })
            this.lastTimeEntries = this.getLastTimeEntries()
        },
        removeTimeEntries(entries: any) {
                //TODO put this into controller and get the entries again from controller
            entries.forEach((entry: any) => {
                const replaceIdx = this.timeEntries.findIndex((e: any) => e.uid == entry.uid)
                if (replaceIdx != -1) {
                    console.log('replaceIdx', replaceIdx)
                    console.log('entry', entry)
                    this.timeEntries.splice(replaceIdx, 1)
                }
            })
            this.lastTimeEntries = this.getLastTimeEntries()
        },        
        updateActiveTimeTracked() {
            if (this.activeTimeEntry.uid) this.activeTimeTracked = DateTimeUtils.convertSecondsToTime(this.activeTimeEntry?.getTimeTracked())
            this.setDaySum()
            setTimeout(() => this.updateActiveTimeTracked(), 15000)  
        },
        setDaySum() {
            this.daySum = 0
            this.lastTimeEntries.forEach((entry: TimeEntry) => {
                this.daySum += entry.getTimeGone()
            });
        },

            //event handler
        handleSwitchDay(direction: any) {
            this.activeDay += 86400*direction
            const midnights = DateTimeUtils.getMidnightToMidnight(new Date())
            if (this.activeDay > midnights.end) {
                this.activeDay -= 86400;
            } else {
                this.getTimeEntries()
            }
        },       
        handlePresencePause(){
            if (this?.activeBreak?.stop == 0) {
                this.updateBreak();
            } else {
                this.createBreak();
            }
        },
        handleOnTimeEntrySwipeLeft(timeEntry: TimeEntry) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteTimeEntryText' ), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.deleteTimeEntry(timeEntry) },
                onCancel: () => { 
                    this.resetSwipe = true
                    this.$nextTick(() => {
                        this.resetSwipe = false
                    })
                }
            });            
        },
        handleOnTimeEntrySwipeRight(timeEntry: TimeEntry) {
            this.editTimeEntry(timeEntry)
        },
        handleToggleTimeEntry(entry: TimeEntry, startstop: string) { 
            console.log('handelToggleTimeEntry activeBreak', this.activeBreak)
            if (startstop == 'start' && this.activeBreak?.stop == 0) {
                this.updateBreak()
            }
            this.toggleTimeEntry(entry, startstop)
        },
        handleOpenWorkForm(){
            this.$router.push('/addtimeentry/')
        },        
    }
})
