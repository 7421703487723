import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "timi-overview-container" }
const _hoisted_2 = { class: "timi-overview-content" }
const _hoisted_3 = { class: "date-slider" }
const _hoisted_4 = { class: "info" }
const _hoisted_5 = { class: "main-content" }
const _hoisted_6 = { class: "headerinfo" }
const _hoisted_7 = { class: "headline" }
const _hoisted_8 = { class: "info headersum" }
const _hoisted_9 = { class: "headeractions" }
const _hoisted_10 = {
  key: 0,
  class: "info"
}
const _hoisted_11 = {
  key: 1,
  class: "info"
}
const _hoisted_12 = {
  key: 2,
  class: "info"
}
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "listitem__delete-action swipe swipe--left" }
const _hoisted_15 = { class: "listitem__content swipe--component" }
const _hoisted_16 = { class: "listitem__detail" }
const _hoisted_17 = {
  key: 0,
  class: "listitem__toggle-startstop"
}
const _hoisted_18 = {
  key: 1,
  class: "listitem__toggle-startstop"
}
const _hoisted_19 = {
  key: 2,
  class: "listitem__toggle-startstop"
}
const _hoisted_20 = { class: "listitem__edit-action swipe swipe--right" }
const _hoisted_21 = { key: 2 }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ButtonGroup = _resolveComponent("ButtonGroup")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_List = _resolveComponent("List")!
  const _component_ActiveWorkBar = _resolveComponent("ActiveWorkBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_IconButton, {
          icon: _ctx.store.getters.svgIcons.arrowbackward,
          class: "",
          onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSwitchDay(-1)))
        }, null, 8, ["icon"]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.displayActiveDay), 1),
        _createVNode(_component_IconButton, {
          icon: _ctx.store.getters.svgIcons.arrowforward,
          onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSwitchDay(1)))
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("header", null, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h2", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.$t('work.works')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.displayActiveDay), 1)
            ]),
            _createElementVNode("p", _hoisted_8, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('label.daysum')) + ":", 1),
              _createTextVNode(" " + _toDisplayString(_ctx.convertSeconds(_ctx.daySum)), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_ButtonGroup, null, {
              default: _withCtx(() => [
                (_ctx.activePresence && _ctx.activePresence.come > 0 && _ctx.activePresence.go == 0)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      text: (_ctx.activeBreak?.stop == 0 ? _ctx.$t('button.stop_pause') : _ctx.$t('button.pause')),
                      primary: true,
                      raised: true,
                      disabled: !_ctx.presenceLoaded || _ctx.readOnly,
                      onOnClick: _ctx.handlePresencePause
                    }, null, 8, ["text", "disabled", "onOnClick"]))
                  : (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      text: _ctx.$t('button.come'),
                      primary: true,
                      raised: true,
                      disabled: !_ctx.presenceLoaded || _ctx.readOnly,
                      onOnClick: _ctx.createPresence
                    }, null, 8, ["text", "disabled", "onOnClick"])),
                _createVNode(_component_Button, {
                  text: _ctx.$t('button.go'),
                  primary: true,
                  raised: true,
                  disabled: _ctx.activePresence && _ctx.activePresence.come == null || _ctx.readOnly,
                  onOnClick: _ctx.updatePresence
                }, null, 8, ["text", "disabled", "onOnClick"])
              ]),
              _: 1
            }),
            (!_ctx.presenceLoaded)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, "Loading..."))
              : (_ctx.activePresence && _ctx.activePresence.come > 0 && _ctx.activePresence.go > 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t('label.goneAt')) + " " + _toDisplayString(_ctx.convertTimeStamp(_ctx.activePresence.go)) + " Uhr", 1))
                : (_ctx.activePresence && _ctx.activePresence.come > 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.$t('label.startedAt')) + " " + _toDisplayString(_ctx.convertTimeStamp(_ctx.activePresence.come)) + " Uhr", 1))
                  : _createCommentVNode("", true)
          ])
        ]),
        (!_ctx.timeEntriesLoaded || _ctx.loading)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "svgicon svgicon--loader",
              innerHTML: _ctx.store.getters.svgIcons.loader
            }, null, 8, _hoisted_13))
          : (_ctx.timeEntries.length >0 )
            ? (_openBlock(), _createBlock(_component_List, {
                key: 1,
                class: "ww-list-timeentries"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lastTimeEntries, (timeEntry) => {
                    return (_openBlock(), _createBlock(_component_ListItem, {
                      key: timeEntry.uid,
                      swipeLeft: true,
                      swipeRight: true,
                      resetSwipe: _ctx.resetSwipe,
                      readOnly: _ctx.readOnly,
                      onOnSwipeLeft: ($event: any) => (_ctx.handleOnTimeEntrySwipeLeft(timeEntry)),
                      onOnSwipeRight: ($event: any) => (_ctx.handleOnTimeEntrySwipeRight(timeEntry))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_IconButton, {
                            icon: _ctx.store.getters.svgIcons.delete,
                            label: _ctx.$t('button.delete'),
                            onOnClick: ($event: any) => (_ctx.deleteTimeEntry(timeEntry))
                          }, null, 8, ["icon", "label", "onOnClick"])
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("h3", null, _toDisplayString(_ctx.getProjectTitle(timeEntry)), 1),
                            _createElementVNode("p", null, _toDisplayString((timeEntry.work ? timeEntry.work.title : '')), 1)
                          ]),
                          (timeEntry.uid == _ctx.activeTimeEntry.uid)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                _createVNode(_component_IconButton, {
                                  icon: _ctx.store.getters.svgIcons.pause,
                                  disabled: _ctx.readOnly,
                                  onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleToggleTimeEntry(_ctx.activeTimeEntry, 'stop'))),
                                  label: _ctx.activeTimeTracked
                                }, null, 8, ["icon", "disabled", "label"])
                              ]))
                            : (timeEntry.editTime)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                  _createVNode(_component_IconButton, {
                                    icon: _ctx.store.getters.svgIcons.playplus,
                                    disabled: _ctx.readOnly,
                                    onOnClick: ($event: any) => (_ctx.handleToggleTimeEntry(timeEntry, 'start')),
                                    label: _ctx.convertSeconds(timeEntry.editTime)
                                  }, null, 8, ["icon", "disabled", "onOnClick", "label"])
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                  _createVNode(_component_IconButton, {
                                    icon: _ctx.store.getters.svgIcons.play,
                                    disabled: _ctx.readOnly,
                                    onOnClick: ($event: any) => (_ctx.handleToggleTimeEntry(timeEntry, 'start')),
                                    label: _ctx.convertSeconds(timeEntry.timeGone)
                                  }, null, 8, ["icon", "disabled", "onOnClick", "label"])
                                ]))
                        ]),
                        _createElementVNode("div", _hoisted_20, [
                          _createVNode(_component_IconButton, {
                            icon: _ctx.store.getters.svgIcons.pencil,
                            label: _ctx.$t('button.edit'),
                            onOnClick: ($event: any) => (_ctx.editTimeEntry(timeEntry))
                          }, null, 8, ["icon", "label", "onOnClick"])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["resetSwipe", "readOnly", "onOnSwipeLeft", "onOnSwipeRight"]))
                  }), 128))
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.$t('emptyState.noActivitiesToday')), 1))
      ])
    ]),
    (_ctx.timeEntriesLoaded)
      ? (_openBlock(), _createBlock(_component_ActiveWorkBar, {
          key: 0,
          activeTimeTracked: _ctx.activeTimeTracked,
          currentWork: _ctx.activeTimeEntry,
          onToggleTimeEntry: _ctx.handleToggleTimeEntry
        }, null, 8, ["activeTimeTracked", "currentWork", "onToggleTimeEntry"]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleOpenWorkForm && _ctx.handleOpenWorkForm(...args))),
      class: _normalizeClass(["ww-button-add", { 'disabled': _ctx.readOnly }]),
      disabled: _ctx.readOnly
    }, [
      _createElementVNode("i", {
        class: "svgicon",
        innerHTML: _ctx.store.getters.svgIcons.add
      }, null, 8, _hoisted_23)
    ], 10, _hoisted_22)
  ]))
}