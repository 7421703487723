import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "timi-active-work-bar"
}
const _hoisted_2 = { class: "timi-active-work-bar-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "work-bar-text-dense" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "time-gone"
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_ctx.currentWork)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.currentWork.uid)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.currentWork?.project?.numberExternal) + " " + _toDisplayString(_ctx.currentWork?.project?.title), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.currentWork?.work?.title), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('work.noCurrentActivity')), 1)
              ])),
          (_ctx.currentWork.uid)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_IconButton, {
                  icon: _ctx.store.getters.svgIcons.pause,
                  label: _ctx.activeTimeTracked,
                  onOnClick: _ctx.handleStopTimeEntry
                }, null, 8, ["icon", "label", "onOnClick"]),
                _createElementVNode("i", {
                  class: "svgicon timi-rotate",
                  innerHTML: _ctx.store.getters.svgIcons.timi
                }, null, 8, _hoisted_7)
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}