
import { defineComponent } from 'vue';
import store from '@/store/index';
import { IconButton } from '@/ui/index';
import { DateTimeUtils } from "@/utils";

export default defineComponent({
    name: "ActiveWorkBar", 
    components: { IconButton },
    props:{
        currentWork: {
            type: Object, 
            default: null
        },
        activeTimeTracked: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            store,
        }
    },
    created() {
        //console.log("currentWork", this.currentWork)
    },
    computed:{
        /* runningTime() {
            //tstamp in seconds
            return this.convertTimeString(this.currentWork.tstamp - this.currentWork.start);
        } */
    },
    methods:{
        convertSeconds(ts: number) {
            return DateTimeUtils.convertTimeStampToTime(ts);
        },
        /* convertTimeString(time : number) {
            let hours : any = Math.floor(time / 3600);
            let minutes : any = Math.floor((time - (hours * 3600)) / 60);
            let seconds : any = time - (hours * 3600) - (minutes * 60);
            let timestring = "";
            console.log("running", hours, minutes, seconds)
            let inHours = false;
            if(hours > 0){
                if (hours < 10) { hours = "0" + hours; }
                timestring += hours + ':';
                inHours = true;
            }
            if (minutes < 10) { minutes = "0" + minutes; }
            timestring += minutes + ':';
        
            if(!inHours){
                if (seconds < 10) { seconds = "0" + seconds; }
                timestring += seconds;
            }
            return timestring + (inHours ? " h" : " min");

        }, */
        async handleStopTimeEntry(){
            this.$emit('toggleTimeEntry', this.currentWork, 'stop')
        },
        async handleStartTimeEntry(){
            this.$emit('toggleTimeEntry', this.currentWork, 'start')
        }
    }
})
